import React, {useState} from 'react';
import "./sikh-history.scss";
import SantSipahiBook from "../books/Sant-Sipahi.pdf";
import SooraSoPehchaniyeBook from "../books/SOORA SO PAHCHANIYE.pdf";
import TheResoluteSikhsBook from "../books/The Resolute Sikhs.pdf";
import BhooleMaragJinehBatayaBook from "../books/Bhule Marag Jine Bataya Final.pdf";
import SikhItihasDianPairhanBook from "../books/SIKH Etihas Diyan Paidan.pdf";
import SikhItihasDeAngauleyNayakBook from "../books/Sikh Itihas De Angaule Nayak.pdf";
import BhaiGurdasJi from "../books/Bhai-Gurdas-Ji.pdf";
import GuruNanakBhrehmandiAdhikaraDaVanjara from "../books/Guru Nanak - Bhrehmandi Adhikara Da Vanjara.pdf";
import MataJitoJi from "../books/Mata Jito Ji (Jit Kaur Ji).pdf";
import MataSahibKaurJi from "../books/Mata Sahib Kaur Ji.pdf";
import BhaiKanhiyaJi from "../books/Bhai Kanhiya Ji PDF.pdf"
import DarveshSahsiandDoorandesh from "../books/Darvesh Sahsi and Doorandesh PDF.pdf"
import KhalsaSaajna from "../books/Khalsa Saajna PDF.pdf"
import MataBhagKaurJi from "../books/Mata Bhag Kaur Ji PDF.pdf"
import RaiBularBhatti from "../books/Rai Bular Bhatti PDF.pdf"
import RangretaGuruKaBeta from "../books/Rangreta Guru Ka Beta PDF.pdf"
import ShahidBhaiBachinterSinghJi from "../books/Shahid Bhai Bachinter Singh Ji PDF.pdf"
import ShahidBhaiSubaigSinghShahbazSinghJi from "../books/Shahid Bhai Subaig Singh Shahbaz Singh Ji PDF.pdf"
import SikhHolocaust from "../books/Sikh Holocaust PDF.pdf"

import SantSipahiBookCover from "../assets/sant-sipahis-cover-page.jpg"
import SooraSoPehchaniyeBookCover from "../assets/soora-so-pachaniye-cover-page.jpg";
import TheResoluteSikhsBookCover from "../assets/the-resolute-sikhs-cover-page.jpg";
import BhooleMaragJinehBatayaBookCover from "../assets/bhole-marag-jinhe-batayea-cover-page.jpg";
import SikhItihasDianPairhanBookCover from "../assets/sikh-etihas-diyan-paidan-cover-page.jpg";
import SikhItihasDeAngauleyNayakBookCover from "../assets/sikh-itihas-de-angaule-nayak-cover-page.jpg";
import BhaiGurdasJiCover from "../assets/bhai-gurdas-ji-cover.png";
import GuruNanakBhrehmandiAdhikaraDaVanjaraCover from "../assets/guru-nanak-bhrehmandi-adhikara-da-vanjara-cover.png";
import MataJitoJiCover from "../assets/mata-jito-ji-jit-kaur-ji-cover.png";
import MataSahibKaurJiCover from "../assets/mata-sahib-kaur-ji-cover.png";
import BhaiKanhiyaJiCover from "../assets/bhai-kanhiya-ji-cover.jpg"
import DarveshSahsiandDoorandeshCover from "../assets/darvesh-sahsi-and-doorandesh-cover.png"
import KhalsaSaajnaCover from "../assets/khalsa-saajna-cover.jfif"
import MataBhagKaurJiCover from "../assets/mata-bhag-kaur-ji-cover.jpg"
import RaiBularBhattiCover from "../assets/rai-bular-bhatti-cover.jpg"
import RangretaGuruKaBetaCover from "../assets/rangreta-guru-ka-beta-cover.jfif"
import ShahidBhaiBachinterSinghJiCover from  "../assets/shahid-bhai-bachinter-singh-ji-cover.jfif"
import ShahidBhaiSubaigSinghShahbazSinghJiCover from "../assets/shahid-bhai-subaig-singh-shahbaz-singh-ji-cover.jpg"
import SikhHolocaustCover from "../assets/sikh-holocaust-cover.jpg"

export default function SikhHistory() {

    return (<>
        <section class="hero">
            <div class="hero-image">
                <div class="home-title">
                    <h1>The Resolute Sikh</h1>
                </div>
            </div>
        </section>

        <section class="table-of-contents">
            <div class="table-title"> 
                <h1>Table of Contents:</h1>
                <h3>*To go to the book quickly, click on the title from the "Table of Contents"*</h3>
                <h2>Books:</h2>
            </div>
            <div class="table-content">
                <ul>
                    <li class="table-element" id="book-one"><a href="#first-book-description">Sant Sipahi (2016)</a></li>
                    <li class="table-element" id="book-two"><a href="#second-book-description">Soora So Pehchaniye (2016)</a></li>
                    <li class="table-element" id="book-three"><a href="#third-book-description">The Resolute Sikhs (2016)</a></li>
                    <li class="table-element" id="book-four"><a href="#fourth-book-description">Bhoole Marag Jineh Bataya (2019)</a></li>
                    <li class="table-element" id="book-five"><a href="#fifth-book-description">Sikh Itihas Dian Pairhan (2019)</a></li>
                    <li class="table-element" id="book-six"><a href="#sixth-book-description">Sikh Itihas De Angauley Nayak (2020)</a></li>
                </ul>
            </div>

            <div class="table-title"> 
               <h2>Shorts:</h2>
            </div>
            <div class="table-content">
                <ul>
                    <li class="table-element" id="short-fifth"><a href="#sixth-short-description">Bhai Kanhiya Ji</a></li>
                    <li class="table-element" id="short-one"><a href="#first-short-description">Bhai Gurdas Ji</a></li>
                    <li class="table-element" id="short-six"><a href="#sixth-short-description">Darvesh Sahsi and Doorandesh Ji</a></li>
                    <li class="table-element" id="short-two"><a href="#second-short-description">Guru Nanak - Bhrehmandi Adhikara Da Vanjara</a></li>
                    <li class="table-element" id="short-seven"><a href="#seventh-short-description">Khalsa Saajna</a></li>
                    <li class="table-element" id="short-eight"><a href="#eight-short-description">Mata Bhag Kaur Ji</a></li>
                    <li class="table-element" id="short-three"><a href="#third-short-description">Mata Jito Ji (Jit Kaur jI)</a></li>
                    <li class="table-element" id="short-four"><a href="#fourth-short-description">Mata Sahib Kaur Ji</a></li>
                    <li class="table-element" id="short-nine"><a href="#ninth-short-description">Rai Bular Bhatti</a></li>
                    <li class="table-element" id="short-ten"><a href="#tenth-short-description">Rangreta Guru Ka Beta</a></li>
                    <li class="table-element" id="short-eleven"><a href="#eleventh-short-description">Shahid Bhai Bachinter Singh Ji</a></li>
                    <li class="table-element" id="short-twelve"><a href="#twelfth-short-description">Shahid Bhai Subaig Singh Shahbaz Singh Ji</a></li>
                    <li class="table-element" id="short-thirteen"><a href="#thirteenth-short-description">Sikh Holocaust</a></li>
                </ul>
            </div>
        </section>

        <section class="list-of-books" id="first-book">
            <hr/>
            <div class="book">
                <div class="grid-container">
                    <div class="book-on-shelf">
                        <div class="book-title" id="first-book-description"><a href="#book-one">Sant Sipahi (2016)</a></div>
                        <div class="book-cover" id="first-book-cover"><a href={SantSipahiBook}><img src={SantSipahiBookCover} alt="Sant Sipahi Cover"/></a></div>
                    </div>

                    <div class="book-on-shelf">
                        <div class="book-title" id="second-book-description"><a href="#book-two">Soora So Pehchaniye (2016)</a></div>
                        <div class="book-cover" id="second-book-cover"><a href={SooraSoPehchaniyeBook}><img src={SooraSoPehchaniyeBookCover} alt="Soora So Pehchaniye Cover"/></a></div>
                    </div>

                    <div class="book-on-shelf">
                        <div class="book-title" id="third-book-description"><a href="#book-three">The Resolute Sikhs (2016)</a></div>
                        <div class="book-cover" id="third-book-cover"><a href={TheResoluteSikhsBook}><img src={TheResoluteSikhsBookCover} alt="The Resolute Sikhs Cover"/></a></div>
                    </div>

                    <div class="book-on-shelf">
                        <div class="book-title" id="fourth-book-description"><a href="#book-four">Bhoole Marag Jineh Bataya (2019)</a></div>
                        <div class="book-cover" id="fourth-book-cover"><a href={BhooleMaragJinehBatayaBook}><img src={BhooleMaragJinehBatayaBookCover} alt="Bhoole Marag Jineh Bataya Cover"/></a></div>
                    </div>

                    <div class="book-on-shelf">
                        <div class="book-title" id="fifth-book-description"><a href="#book-five">Sikh Itihas Dian Pairhan (2019)</a></div>
                        <div class="book-cover" id="fifth-book-cover"><a href={SikhItihasDianPairhanBook}><img src={SikhItihasDianPairhanBookCover} alt="Sikh Ithias Diyan Paidan Cover"/></a></div>
                    </div>

                    <div class="book-on-shelf">
                        <div class="book-title" id="sixth-book-description"><a href="#book-six">Sikh Itihas De Angauley Nayak (2020)</a></div>
                        <div class="book-cover" id="sixth-book-cover"><a href={SikhItihasDeAngauleyNayakBook}><img src={SikhItihasDeAngauleyNayakBookCover} alt="Sikh Itihas De Angauley Nayak Cover"/></a></div>
                    </div>
                </div>
            </div>
        </section>

        <section class="list-of-books" id="second-book">
            <hr/>
            <div class="book">
                <div class="grid-container">
                    <div class="book-on-shelf">
                        <div class="book-title" id="fifth-short-description"><a href="#shot-five">Bhai Kanhiya Ji</a></div>
                        <div class="book-cover" id="fifth-book-cover"><a href={BhaiKanhiyaJi}><img src={BhaiKanhiyaJiCover} alt="Bhai Kanhiya Ji Cover"/></a></div>
                    </div>

                    <div class="book-on-shelf">
                        <div class="book-title" id="first-short-description"><a href="#short-one">Bhai Gurdas Ji</a></div>
                        <div class="book-cover" id="first-book-cover"><a href={BhaiGurdasJi}><img src={BhaiGurdasJiCover} alt="Bhai Gurdas Ji Cover"/></a></div>
                    </div>

                    <div class="book-on-shelf">
                        <div class="book-title" id="sixth-short-description"><a href="#short-six">Darvesh Sahsi and Doorandesh Ji</a></div>
                        <div class="book-cover" id="sixth-book-cover"><a href={DarveshSahsiandDoorandesh}><img src={DarveshSahsiandDoorandeshCover} alt="Darvesh Sahsi and Doorandesh Cover"/></a></div>
                    </div>

                    <div class="book-on-shelf">
                        <div class="book-title" id="second-short-description"><a href="#short-two">Guru Nanak</a></div>
                        <div class="book-cover" id="second-book-cover"><a href={GuruNanakBhrehmandiAdhikaraDaVanjara}><img src={GuruNanakBhrehmandiAdhikaraDaVanjaraCover} alt="Guru Nanak - Bhrehmandi Adhikara Da Vanjara Cover"/></a></div>
                    </div>

                    <div class="book-on-shelf">
                        <div class="book-title" id="seventh-short-description"><a href="#short-seven">Khalsa Saajna</a></div>
                        <div class="book-cover" id="seventh-book-cover"><a href={KhalsaSaajna}><img src={KhalsaSaajnaCover} alt="Khalsa Saajna Cover"/></a></div>
                    </div>

                    <div class="book-on-shelf">
                        <div class="book-title" id="eight-short-description"><a href="#short-eight">Mata Bhag Kaur Ji</a></div>
                        <div class="book-cover" id="eight-book-cover"><a href={MataBhagKaurJi}><img src={MataBhagKaurJiCover} alt="Mata Bhag Kaur Ji Cover"/></a></div>
                    </div>

                    <div class="book-on-shelf">
                        <div class="book-title" id="third-short-description"><a href="#short-three">Mata Jito Ji (Jit Kaur Ji)</a></div>
                        <div class="book-cover" id="third-book-cover"><a href={MataJitoJi}><img src={MataJitoJiCover} alt="Mata Jito Ji (Jit Kaur Ji) Cover"/></a></div>
                    </div>

                    <div class="book-on-shelf">
                        <div class="book-title" id="fourth-short-description"><a href="#short-four">Mata Sahib Kaur Ji</a></div>
                        <div class="book-cover" id="fourth-book-cover"><a href={MataSahibKaurJi}><img src={MataSahibKaurJiCover} alt="Mata Sahib Kaur Ji Cover"/></a></div>
                    </div>

                    <div class="book-on-shelf">
                        <div class="book-title" id="ninth-short-description"><a href="#short-nine">Rai Bular Bhatti</a></div>
                        <div class="book-cover" id="ninth-book-cover"><a href={RaiBularBhatti}><img src={RaiBularBhattiCover} alt="Rai Bular Bhatti Cover"/></a></div>
                    </div>

                    <div class="book-on-shelf">
                        <div class="book-title" id="tenth-short-description"><a href="#short-ten">Rangreta Guru Ka Beta</a></div>
                        <div class="book-cover" id="tenth-book-cover"><a href={RangretaGuruKaBeta}><img src={RangretaGuruKaBetaCover} alt="Rangreta Guru Ka Beta Cover"/></a></div>
                    </div>

                    <div class="book-on-shelf">
                        <div class="book-title" id="eleventh-short-description"><a href="#short-eleven">Shahid Bhai Bachinter Singh Ji</a></div>
                        <div class="book-cover" id="eleventh-book-cover"><a href={ShahidBhaiBachinterSinghJi}><img src={ShahidBhaiBachinterSinghJiCover} alt="Shahid Bhai Bachinter Singh Ji Cover"/></a></div>
                    </div>

                    <div class="book-on-shelf">
                        <div class="book-title" id="twelfth-short-description"><a href="#short-twelve">Shahid Bhai Subaig Singh Shahbaz Singh Ji</a></div>
                        <div class="book-cover" id="twelfth-book-cover"><a href={ShahidBhaiSubaigSinghShahbazSinghJi}><img src={ShahidBhaiSubaigSinghShahbazSinghJiCover} alt="Shahid Bhai Subaig Singh Shahbaz Singh Ji Cover"/></a></div>
                    </div>

                    <div class="book-on-shelf">
                        <div class="book-title" id="thirteenth-short-description"><a href="#short-thirteen">Sikh Holocaust</a></div>
                        <div class="book-cover" id="thirteenth-book-cover"><a href={SikhHolocaust}><img src={SikhHolocaustCover} alt="Sikh Holocaust Cover"/></a></div>
                    </div>
                </div>
            </div>
        </section></>
    )
}