import React, {useEffect} from 'react';
import "./home.scss";


export default function Home() {
    return (<>
        <section class="hero">
            <div class="hero-image">
                <div class="home-title">
                    <h1>The Resolute Sikh</h1>
                </div>
            </div>
        </section></>
    )
}