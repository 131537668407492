import React, {useState} from 'react';
import "./contactus.scss";

import Home from "../home/home";

export default function ContactsUs() {

    return (<>
        <Home/>

        <section class="table-of-contents">
            <div class="table-title"> 
                <h1>Contact Us At:</h1>
            </div>
            <div class="table-content">
                <ul>
                    <li class="table-element" id="book-one"><a href="#first-book-description">theresolutesikh@gmail.com</a></li>
                </ul>
            </div>
        </section></>
    )
}