import React, {useState} from 'react';
import "./writings.scss";


export default function Writings() {

    return (<>
        <section class="hero">
            <div class="hero-image">
                <div class="home-title">
                    <h1>The Resolute Sikh</h1>
                </div>
            </div>
        </section>

        <section class="buttons-to-pages">
            <hr/>
            <h1>Writings:</h1>
            <div class="grid-container">
                <div class="grid-item"><a href="/writings/sikhhistory" id="first-button">Sikh History</a></div>
                <div class="grid-item"><a href="/writings/poetry" id="second-button">Poetry</a></div>
                <div class="grid-item"><a href="/writings/mentionedin" id="third-button">Mentioned In</a></div>
            </div>
            <br></br>
            <div class="grid-container">
                <div class="grid-item"><a href="#top" id="sixth-button">Back To Top</a></div>
                <div class="grid-item"><a href="/home" id="seventh-button">Home</a></div>
            </div>
            <hr class="bottomOfPage"/>
        </section></>
    )
}