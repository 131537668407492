import logo from "./logo.svg";
import "./App.css";
import React, { Component, useState} from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom";

import Nav from "./component/nav/nav";
import Error from "./component/writings/error/error";
import Home from "./component/home/home";
import ButtonsForHome from "./component/buttonstopageshome/buttonstopageshome";
import ButtonsForHomeDark from "./component/buttonstopageshomedark/buttonstopageshomedark";
import About from "./component/about/About";
import Writings from "./component/writings/writings";
import SikhHistory from "./component/writings/sikh-history/sikh-history";
import Poetry from "./component/writings/Poetry/poetry";
import MentionedIn from "./component/writings/mentioned-in/mentioned-in";
import ContactUs from "./component/contactus/contactus";

function App() {
  const [current, setCurrent] = useState("");

  return (
    <div className="App">
      {/* 
        In the Nav we can add attribuite current.
      */}

      <Router>
        <Switch>
          <Route exact path={["/", "/home"]}>
            <Nav current="Home" />
            <Home />
            <ButtonsForHome />
          </Route>
          <Route exact path="/about">
            <Nav current="About" />
            <About />
            <ButtonsForHome />
          </Route>
          <Route exact path="/writings">
            <Nav current="Writings" />
            <Writings />
          </Route>
          <Route exact path="/writings/sikhhistory">
            <Nav current="SikhHistory" />
            <SikhHistory />
            {/* <ButtonsForHomeDark /> */}
          </Route>
          <Route exact path="/writings/poetry">
            <Nav current="Poetry" />
            <Poetry />
            {/* <ButtonsForHomeDark /> */}
          </Route>
          <Route exact path="/writings/mentionedin">
            <Nav current="MentionedIn" />
            <MentionedIn />
            {/* <ButtonsForHome /> */}
          </Route>
          <Route exact path="/ContactUs">
            <Nav current="ContactUs" />
            <ContactUs />
            <ButtonsForHome />
          </Route>
          <Route path="*">
            <Nav current="Error" />
            <Error />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
