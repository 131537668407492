import React, {useState} from 'react';
import "./About.scss";
import AboutMe from "./About-Me-Final-PDF.pdf";
import TheSikhEktaManchNewDelhi from "../../assets/the-sikh-ekta-manch-new-delhi.jpg"
import PunjabiSahitSamelan from "../../assets/punjabi-sahit-samelan.jpeg"
import PunjabiSahitSamelanCover from "../../assets/punjabi-sahit-samelan-cover.jpg"

export default function About() {

    return (<>
        <section class="second-hero">
            <div class="hero-image">
                <div class="home-title">
                    <h1>About Rajinder Singh Jolly</h1>
                </div>
            </div>
        </section>

        <section id="about-me" class="inner-feelings">
            <hr/>
            <center>
                <a href="/static/media/About-Me-Final-PDF.035089de.pdf"><h2>*Click here to open the PDF if it does not load*</h2></a>
                <object data={AboutMe}/>
            </center>
        </section>
        
        <section class="list-of-books" id="second-book">
            <hr/>
            <div class="book">
                <div class="grid-container-two">
                    <div class="awards" id="first-book-description"><a class="awards" href="#book-one"/>
                        <h2 class="awards">The Punjabi Sahit Samelan Delhi Honor (2007):</h2>
                        <h3 class="awards">Wednesday, July 11<sup>th</sup>, 2007</h3>
                    </div>
                    <div class="award" id="first-book-cover"><img src={PunjabiSahitSamelan} alt="The Punjabi Sahit Samelan Delhi Honor (2007)"/></div>
                </div>
            </div>
        </section>

        <section class="list-of-books" id="first-book">
            <hr/>
            <div class="book">
                <div class="grid-container-two">
                    <div class="awards" id="first-book-description"><a class="awards" href="#book-one"/>
                        <h2 class="awards">The Sikh Ekta Manch New Delhi Reception (2009):</h2>
                        <h3 class="awards">Conference Hall, Guru Gobind Singh Bhawan, Gurdwara Rekab Ganj, New Delhi</h3>
                        <h4 class="awards">Monday, August 3<sup>rd</sup>, 2009</h4>
                    </div>
                    <div class="grid-double-image">
                        <div class="book-cover" id="first-book-cover"><img src={PunjabiSahitSamelanCover} alt="The Sikh Ekta Manch New Delhi Reception Cover (2009)"/></div>
                        <div class="book-cover" id="first-book-cover"><img src={TheSikhEktaManchNewDelhi} alt="The Sikh Ekta Manch New Delhi Reception List (2009)"/></div>
                    </div>
                </div>
            </div>
        </section></>
    )
}