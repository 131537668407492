import React, {useState, useEffect} from 'react';
import "./buttonstopageshome.scss";

export default function HomeButtonsPages() {

    useEffect(()=>{
        var longText = document.getElementById("third-button")
        var allButtons = document.getElementsByClassName("grid-item");
        var buttonLength = longText.getBoundingClientRect().width;
            console.log(allButtons);

            for (var button of allButtons){
                console.log(button)
                button.style.width=`${buttonLength}px`;
            }

    longText.addEventListener('mouseover', () => {
        longText.innerHTML="Books Rajinder Singh Jolly Is Mentioned In"
    })
    longText.addEventListener('mouseout', () => {
        longText.innerHTML="Mentioned In"
    })
    },[])

    return (<>
        <section class="buttons-to-pages">
            <hr/>
            <h1>Pages:</h1>
            <div class="grid-container">
                <div class="grid-item"><a href="/writings/sikhhistory" id="first-button">Sikh History</a></div>
                <div class="grid-item"><a href="/writings/poetry" id="second-button">Poetry</a></div>
                <div class="grid-item"><a href="/writings/mentionedin" id="third-button" >Mentioned In</a></div>
            </div>
            <br></br>
            <div class="grid-container">
                <div class="grid-item"><a href="/contactus" id="fourth-button">Contact Us</a></div>
                <div class="grid-item"><a href="/about" id="fifth-button">About</a></div> 
            </div>
            <br></br>
            <div class="grid-container">
                <div class="grid-item"><a href="#top"id="sixth-button">Back To Top</a></div>
            </div>
            <hr class="bottomOfPage"/>
        </section></>
    )
}