import React, {useState} from 'react';
import "./mentioned-in.scss";
import HarchandBediCover from "../assets/dr-harchand-singh-bedi-book-cover-page.jpg";
import AmirKaurCover from "../assets/dr-amir-kaur-cover-page.jpg";
import ManindarKaur from "../assets/mrs-manindar-kaur-cover-page.jpg";

import Home from "../../home/home";

export default function MentionedIn() {

    return (<>
        <Home/>

        <section class="table-of-contents">
            <div class="table-title"> 
                <h1>Table of Contents:</h1>
            </div>
            <div class="table-content">
                <ul>
                    <li class="table-element" id="book-one"><a href="#first-book-description">Parvasi Punjabi Sahit Sandarb Kosh (Kavita) Part I-IV</a></li>
                    <li class="table-element" id="book-two"><a href="#second-book-description">Punjabi Safarnama Sahit: Shailee Vigyanak Vishleshann (Literary Criticism) (Critical Analysis of 5 books (Inlcuding Battan Samundron Par Dian))</a></li>
                    <li class="table-element" id="book-three"><a href="#third-book-description">"Roobroo" Kav-Ssangreh Vich Parvasi Chetna Da Muhandra: Alochnatmak-Adhian</a></li>
                </ul>
            </div>
        </section>

        <section class="list-of-books" id="first-book">
            <hr/>
            <div class="book">
                <div class="grid-container-two">
                    <div class="book-title" id="first-book-description"><a class="mentioned-books" href="#book-one"> 
                        <h2 class="mentioned-books">Parvasi Punjabi Sahit Sandarb Kosh (Kavita) Part I-IV:</h2>
                        <h4 class="mentioned-books">Written By: (Professor) (Dr.) Harchand Singh Bedi</h4>
                        <h5 class="mentioned-books">Head of Immigrant Studies Guru Nanak Dev University, Amritsar</h5>
                        <h6 class="mentioned-books">Published By: Vavi Sahit Parkashan, Amritsar</h6></a>
                    </div>
                    <div class="book-cover" id="first-book-cover"><img src={HarchandBediCover} alt="Parvasi Punjabi Sahit Sandarb Kosh (Kavita) Part I-IV Cover"/></div>
                </div>
            </div>
        </section>
        <section class="list-of-books" id="second-book">
            <hr/>
            <div class="book">
                <div class="grid-container">
                    <div class="book-title" id="second-book-description"><a class="mentioned-books" href="#book-two">
                        <h2 class="mentioned-books">Punjabi Safarnama Sahit: Shailee Vigyanak Vishleshann (Literary Criticism) (Critical Analysis of 5 books (Inlcuding Battan Samundron Par Dian)):</h2>
                        <h4 class="mentioned-books">Written By: Dr. Amir Kaur</h4>
                        <h5 class="mentioned-books">B-60, 2nd Floor, Fateh Nagar, New Delhi</h5>
                        <h6 class="mentioned-books">Published By: Manpreet Parkashan, Delh-31</h6></a>
                    </div>
                    <div class="book-cover" id="second-book-cover"><img src={AmirKaurCover} alt="Punjabi Safarnama Sahit: Shailee Vigyanak Vishleshann (Literary Criticism) (Critical Analysis of 5 books (Inlcuding Battan Samundron Par Dian)) Cover"/></div>
                </div>
            </div>
        </section>
        <section class="list-of-books" id="third-book">
            <hr/>
            <div class="book">
                <div class="grid-container">
                    <div class="book-title" id="third-book-description"><a class="mentioned-books" href="#book-three">
                        <h2 class="mentioned-books">"Rooboroo" Kav-Sangreh Vich Parvasi Chetna Da Muhandra: Alochnatmak-Adhian:</h2>
                        <h4 class="mentioned-books">Written By: Mrs. Manindar Kaur</h4>
                        <h5 class="mentioned-books">M-Phil Student, Delhi University (2009)</h5>
                        <h6 class="mentioned-books">Published By: Bhai Chatar Singh and Jeevan Singh, Amritsar</h6></a>
                    </div>
                    <div class="book-cover" id="third-book-cover"><img src={ManindarKaur} alt=" &quot Rooboroo&quot Kav-Sangreh Vich Parvasi Chetna Da Muhandra: Alochnatmak-Adhian Cover"/></div>
                </div>
            </div>
        </section></>
    )
}