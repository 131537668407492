import React, { useState, useEffect } from "react";
import "./nav.scss";
import navIcon from "../../assets/nav-icon.svg";
import navExit from "../../assets/exit.svg";

export default function Nav({ current, setCurrent }) {
  const [navOpen, setNavOpen] = useState(false);

  useEffect(() => {
    if (current === "ContactUs") {
      document.getElementById(current).classList.add("current-contact");
      document.getElementById(current).classList.remove("contact");
    } else if (current === "Writings") {
      var change = document.getElementById("write");
      change.innerHTML = "<span>Writings</span>";
      // document.getElementById("write").classList.add("current");
    } else if (current === "Error") {
    } else {
      document.getElementById(current).classList.add("current");
    }

    if (current === "SikhHistory") {
      var change = document.getElementById("write");
      change.innerHTML = "Writings <span>/Sikh History</span>";
    }

    if (current === "Poetry") {
      var change = document.getElementById("write");
      change.innerHTML = "Writings <span>/Poetry</span>";
    }

    if (current === "MentionedIn") {
      var change = document.getElementById("write");
      change.innerHTML = "Writings <span>/Mentioned In</span>";
    }
    let mobileBtnWritings = document.getElementById("Writings");
    const mobileBtn = document.getElementById("mobile-cta");
    var nav = document.querySelector("nav");
    var mobileBtnExit = document.getElementById("mobile-exit");
    // console.log(mobileBtnWritings)
    var drop = document.getElementById("show-inside");

    var longText = document.getElementById("third-button");

    var allButtons = document.getElementsByClassName("grid-item");

    mobileBtn.addEventListener("click", () => {
      nav.classList.add("menu-btn");
    });

    mobileBtnExit.addEventListener("click", () => {
      nav.classList.remove("menu-btn");
    });

    // longText.addEventListener('mouseover', () => {
    //     longText.innerHTML="Books Rajinder Singh Jolly Is Mentioned In"
    // })
    // longText.addEventListener('mouseout', () => {
    //     longText.innerHTML="Mentioned In"
    // })

    mobileBtnWritings.addEventListener("mouseover", () => {
      drop.style.display = "block";
      var location = document.getElementById("write").getBoundingClientRect();
      // console.log(location)
      console.log(location.left);
      if (window.innerWidth > 768) {
        drop.style.position = "absolute";
        drop.style.left = `${
          location.left - (drop.getBoundingClientRect().width - 90) / 2
        }px`;
        drop.style.top = `${location.top + location.height}px`;
        console.log(drop.getBoundingClientRect());
      } else {
        drop.style.position = "relative";
        drop.style.left = "inherit";
        drop.style.top = "inherit";
      }
    });
    mobileBtnWritings.addEventListener("mouseout", () => {
      drop.style.display = "none";
    });
  }, []);

  return (
    <>
      <div class="navbar">
        <div class="container">
          <a class="name" href="/home">
            The <span>Resolute</span> Sikh
          </a>
          <nav class={navOpen ? "outside-nav menu-btn" : "outside-nav"}>
            <img
              id="mobile-exit"
              class="mobile-menu-exit"
              src={navExit}
              alt="Close Navigation Bar"
              onClick={() => setNavOpen(!navOpen)}
            />
            <ul class="primary-nav">
              <li id="Home">
                <a href="/home">Home</a>
              </li>
              <li id="Writings" class="drop">
                <a href="/writings" id="write">
                  Writings
                </a>
                <ul id="show-inside" class="inside-list">
                  <li id="SikhHistory" class="drop-item">
                    <a href="/writings/sikhhistory">Sikh History</a>
                  </li>
                  <li id="Poetry" class="drop-item">
                    <a href="/writings/poetry">Poetry</a>
                  </li>
                  <li id="MentionedIn" class="drop-item">
                    <a href="/writings/mentionedin">Mentioned In</a>
                  </li>
                </ul>
              </li>
              <li id="About">
                <a href="/about">About</a>
              </li>
              <li id="ContactUs" class="contact">
                <a id="contactusformat" href="/contactus">
                  Contact Us
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <img
          id="mobile-cta"
          class="mobile-menu"
          src={navIcon}
          alt="Open Navigation Bar"
          onClick={() => setNavOpen(!navOpen)}
        />
      </div>
    </>
  );
}
