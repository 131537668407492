import React, {useState} from 'react';
import "./poetry.scss";
import BanjarShikweBook from "../books/Banjar Shikwe Final PDF.pdf";
import GunchaGulabBook from "../books/Guncha Gulab Final 7-29-2020 PDF.pdf";
import RoobrooBook from "../books/Roobroo PDF.pdf";
import GardishBook from "../books/Gardish PDF.pdf";
import BanjarShikweBookCover from "../assets/banjar-shikwe-cover-page.jpg";
import GunchaGulabBookCover from "../assets/guncha-gulab-cover-page.jpg";
import RoobrooBookCover from "../assets/roobroo-cover-page.jpg";
import GardishBookCover from "../assets/gardish-cover-page.jpg";

import Home from "../../home/home";

export default function Poetry() {

    return (<>
        <Home/>

        <section class="table-of-contents">
            <div class="table-title"> 
                <h1>Table of Contents:</h1>
                <h3>*To go to the book quickly, click on the title from the "Table of Contents"*</h3>
            </div>
            <div class="table-content">
                <ul>
                    <li class="table-element" id="book-one"><a href="#first-book-description">Banjar Shikwe (2000)</a></li>
                    <li class="table-element" id="book-two"><a href="#second-book-description">Guncha Gulab (2006)</a></li>
                    <li class="table-element" id="book-three"><a href="#third-book-description">Roobroo (2007)</a></li>
                    <li class="table-element" id="book-four"><a href="#fourth-book-description">Gardish (2008)</a></li>
                </ul>
            </div>
        </section>

        <section class="list-of-books" id="first-book">
            <hr/>
            <div class="book">
                <div class="grid-container">
                    <div class="book-on-shelf">
                        <div class="book-title" id="first-book-description"><a href="#book-one">Banjar Shikwe (2000)</a></div>
                        <div class="book-cover" id="first-book-cover"><a href={BanjarShikweBook}><img src={BanjarShikweBookCover} alt="Banjar Shikwe Cover"/></a></div>
                    </div>

                    <div class="book-on-shelf">
                        <div class="book-title" id="second-book-description"><a href="#book-two">Guncha Gulab (2006)</a></div>
                        <div class="book-cover" id="second-book-cover"><a href={GunchaGulabBook}><img src={GunchaGulabBookCover} alt="Guncha Gulab Cover"/></a></div>
                    </div>

                    <div class="book-on-shelf">
                        <div class="book-title" id="third-book-description"><a href="#book-three">Roobroo (2007)</a></div>
                        <div class="book-cover" id="third-book-cover"><a href={RoobrooBook}><img src={RoobrooBookCover} alt="Roobroo Cover"/></a></div>
                    </div>

                    <div class="book-on-shelf">
                        <div class="book-title" id="fourth-book-description"><a href="#book-four">Gardish (2008)</a></div>
                        <div class="book-cover" id="fourth-book-cover"><a href={GardishBook}><img src={GardishBookCover} alt="Gardish Cover"/></a></div>
                    </div>
                </div>
            </div>
        </section></>
    )
}