import React, {useState} from 'react';
import "./error.scss";


export default function Error() {
    return (<>
        <section class="hero">
            <div class="hero-image">
                <div class="home-title">
                    <h1>404 <br></br> Page Not Found</h1>
                </div>
            </div>
        </section></>
    )
}